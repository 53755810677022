ArticleEditor.add('block', 'block.checkbox', {
	translations: {
		'en': {
			"blocks": {
				"checkbox": "Checkbox"
			}
		},
		'de': {
			"blocks": {
				"checkbox": "Kontrollkästchen"
			}
		}
	},
	mixins: ['block'],
	type: 'checkbox',
	editable: true,
	parser: {
		tag: 'p',
		parse: function($el) {
			/*if ($el.hasClass('arx-checkbox')) {
				$el.nodes[0].style.border = '1px solid red';
				$el.nodes[0].onclick = ArticleEditorCheckboxPluginClickEvent;
			}*/
			return ($el.hasClass('arx-checkbox')) ? 'checkbox' : false;
		}
	},
	toolbar: {
		add: { command: 'addbar.popup', title: '## buttons.add ##' },
		format: { command: 'format.popup', title: '## buttons.format ##'},
		alignment: { command: 'block.popup', observer: 'block.observe', title: '## buttons.align ##' },
		bold: { command: 'inline.set', title: '## buttons.bold ##', params: { tag: 'b' } },
		italic: { command: 'inline.set', title: '## buttons.italic ##', params: { tag: 'i' } },
		deleted: { command: 'inline.set', title: '## buttons.deleted ##', params: { tag: 'del' } },
		link: { command: 'link.popup', title: '## buttons.link ##' }
	},
	control: {
		trash: { command: 'block.remove', title: '## buttons.delete ##' },
		duplicate: { command: 'block.duplicate', title: '## buttons.duplicate ##'  }
	},

	create: function() {
		var $chk = this.dom('<p class="arx-checkbox"><br></p>');

		return $chk;
	},
	/*handleTab: function(e, key, event) {
		e.preventDefault();
		//var parentInstance = this.getFigure();
		//this.app.block.set(parentInstance);
		return true;
	},*/
	handleEnter: function(e, key, event) {

		var last = this.$block.html().search(/\n$/);

		e.preventDefault();

		if (this.isCaretEnd() && last === -1) {
			this.app.insertion.insertEmptyBlock({ position: 'after', caret: 'end' });
		} else {
			this.app.insertion.insertEmptyBlock({ position: 'after', caret: 'end' });
		}

		return true;
	},
	handleDelete: function (e) {
		if (this.$block && this.$block.nodes[0]) {
			if (!this.$block.nodes[0].textContent) {
				this.app.insertion.insertEmptyBlock({ position: 'after', caret: 'end' });
				this.$block.nodes[0].remove()
			}
		}
	}
});


ArticleEditor.add('plugin', 'checkbox', {
	start: function () {
		this.app.shortcut.add('ctrl+1, meta+1', {
			command: 'checkbox.toggle',
			name: 'Ctrl+1',
			title: '## blocks.checkbox ##'
		});
		this.app.addbar.add('checkbox', {
			title: '## blocks.checkbox ##',
			icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14.83" width="16" height="16"><path d="M7.42,8.16,5,5.43,3.13,7.09l2.4,2.73,1.63,1.85.06.07L16,4,14.31,2.1ZM13,13H1.85V1.85H13v.32L14.51.82A1.85,1.85,0,0,0,13,0H1.85A1.85,1.85,0,0,0,0,1.85V13a1.85,1.85,0,0,0,1.85,1.86H13A1.86,1.86,0,0,0,14.83,13V6.15L13,7.78Z"/></svg>',
			command: 'checkbox.toggle'
		});
		//this.app.opts.format.push('checkbox');
		/*this.app.opts.formatObj.checkbox = {
			title: '<label><input type="checkbox"> ## blocks.checkbox ##</label>',
			type: 'checkbox',
			shortcut: 'Ctrl+1'
		}*/
		//this.app.block.format({ tag: 'div' });
	},
	subscribe: {
		'editor.click': function (click_ev) {

			var e = click_ev && click_ev.params && click_ev.params.e ? click_ev.params.e : null;

			if (!e || !e.target || !e.target.classList || !e.target.classList.contains('arx-checkbox')) return;

			if (e.layerX > 22 || e.layerY > 22) return;

			var $inp = e.target,
				state = $inp.classList.contains('arx-checkbox-checked');
			if (state) {
				$inp.classList.remove('arx-checkbox-checked')
			} else {
				$inp.classList.add('arx-checkbox-checked');
			}
		}
	},
    toggle: function() {

	    var block_instance = this.app.block.get(),
		    $block_dom_el = block_instance.getBlock(),
		    block_type = block_instance.getType(),
		    block_html = block_instance.getHtml() || '<br>',
		    replace_by_instance;

	    if (block_type === 'checkbox') {

	    	var $chk = $block_dom_el.get(),
			    state = $chk.classList ? $chk.classList.contains('arx-checkbox-checked') : false;

	    	if (state) {
	    		// replace to empty
			    var replace_by_ext_instance = this.app.create('block.paragraph'),
				    replace_html = $block_dom_el.html();

			    replace_by_ext_instance.getBlock().html(replace_html ? replace_html : '<br>');

			    this.app.block.change(replace_by_ext_instance)

		    } else {
			    $chk.classList.add('arx-checkbox-checked');
		    }
	    } else {
		    replace_by_instance = this.app.create('block.checkbox');
		    replace_by_instance.getBlock().html(block_html);

		    this.app.block.change(replace_by_instance);

		    this.app.caret.set(replace_by_instance.getBlock(), 'start');
	    }

	    this.app.popup.close();
    }

});
